import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "card"
}
const _hoisted_3 = { class: "card-header" }
const _hoisted_4 = { class: "card-toolbar" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "card card-bordered mb-5" }
const _hoisted_7 = { ref: "invoice_information" }
const _hoisted_8 = { class: "card-footer" }
const _hoisted_9 = { class: "container" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-sm-12 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_socket = _resolveComponent("vc-socket")!
  const _component_vc_modal_payment_warning = _resolveComponent("vc-modal-payment-warning")!
  const _component_vc_button_navigator = _resolveComponent("vc-button-navigator")!
  const _component_vc_health_top = _resolveComponent("vc-health-top")!
  const _component_vc_health_middle = _resolveComponent("vc-health-middle")!
  const _component_vc_health_family = _resolveComponent("vc-health-family")!
  const _component_vc_invoice_information = _resolveComponent("vc-invoice-information")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_vc_button_save = _resolveComponent("vc-button-save")!
  const _component_vc_invoice_modal = _resolveComponent("vc-invoice-modal")!
  const _component_vc_invoice_has_pending = _resolveComponent("vc-invoice-has-pending")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_vc_socket, {
      channel: `invoice-generated-health-${_ctx.currentLead}`,
      listen: "MakeinvoiceEvent",
      onAction: _ctx.redirectIt
    }, null, 8, ["channel", "onAction"]),
    _createVNode(_component_vc_modal_payment_warning, {
      ref: "payment_warning",
      title: "",
      message: "Invoice Generation In Progress. Do not Refresh"
    }, null, 512),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[4] || (_cache[4] = [
          _createElementVNode("div", { class: "card" }, [
            _createElementVNode("div", { class: "card-body" }, "Loading Information Please Wait...")
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (!_ctx.loading && _ctx.health)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "card-title" }, "Make Invoice", -1)),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_vc_button_navigator, {
                text: "Back",
                value: "CustomerDetails",
                "class-name": "btn btn-sm btn-flex btn-info btn-active-info fw-bolder",
                "is-icon": "",
                params: { customer_id: _ctx.health.id }
              }, null, 8, ["params"])
            ])
          ]),
          _createVNode(_component_el_form, {
            ref: "ruleFormRef",
            model: _ctx.invoice,
            "status-icon": "",
            rules: _ctx.rules,
            id: "healthForm",
            "scroll-to-error": ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_vc_health_top, {
                  ref: "top",
                  onReValidate: _ctx.revalidate,
                  onTab: _ctx.checkform,
                  isDisbaled: ""
                }, null, 8, ["onReValidate", "onTab"]),
                _createVNode(_component_vc_health_middle, {
                  ref: "middle",
                  onReValidate: _ctx.revalidate,
                  "disabled-insurance-for": _ctx.health.insured_type_id_prev !== 0,
                  "disabled-insurance-forself": "",
                  disabled: true
                }, null, 8, ["onReValidate", "disabled-insurance-for"]),
                (_ctx.health.insured_type_id >= 1)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "separator border-2 my-10" }, null, -1)),
                      _createElementVNode("div", _hoisted_6, [
                        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "card-header ribbon mb-5" }, [
                          _createElementVNode("div", { class: "card-title mb-5 mt-5" }, [
                            _createElementVNode("h6", { class: "card-subtitle text-muted" }, " Policy Holder Details ")
                          ])
                        ], -1)),
                        _createVNode(_component_vc_health_family, {
                          isEdit: true,
                          isDisabled: true,
                          onReValidate: _ctx.revalidate
                        }, null, 8, ["onReValidate"])
                      ])
                    ], 64))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_vc_invoice_information, {
                    ref: "invoiceRef",
                    onReValidate: _ctx.revalidate,
                    onUploadFile: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleFileUpload($event))),
                    onUploadChequeFile: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleChequeFileUpload($event)))
                  }, null, 8, ["onReValidate"])
                ], 512)
              ])
            ]),
            _: 1
          }, 8, ["model", "rules"]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_vc_button_navigator, {
                    disabled: _ctx.disabledButton,
                    value: "CustomerDetails",
                    params: { customer_id: _ctx.health.id }
                  }, null, 8, ["disabled", "params"]),
                  _createVNode(_component_vc_button_save, {
                    disabled: _ctx.disabledButton,
                    loading: _ctx.clickLoading,
                    text: 'Save',
                    onSubmit: _cache[2] || (_cache[2] = ($event: any) => (_ctx.makeInvoice(_ctx.ruleFormRef)))
                  }, null, 8, ["disabled", "loading"])
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_vc_invoice_modal, {
      open: _ctx.openModal,
      onCancelModal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.closeModal($event))),
      isError: _ctx.isError,
      ref: "invoiceModalRef"
    }, null, 8, ["open", "isError"]),
    (_ctx.pending_invoice)
      ? (_openBlock(), _createBlock(_component_vc_invoice_has_pending, {
          key: 2,
          lead_id: _ctx.health.has_pending_invoice.lead_id,
          customer_id: _ctx.health.has_pending_invoice.customer_id,
          merchant_reference: _ctx.pending_merchant_ref,
          ref: "hasPendingInvoice"
        }, null, 8, ["lead_id", "customer_id", "merchant_reference"]))
      : _createCommentVNode("", true)
  ], 64))
}